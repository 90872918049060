import { createStore, createLogger } from 'vuex'

import Requests from './modules/requests.store'
import Receipts from './modules/receipts.store'

import Auth from './modules/auth'
import Accounting from './modules/accounting'
import AccountingSettings from './modules/accounting-settings'
import Approvals from './modules/approvals'
import ApprovalSettings from './modules/approvals-settings'
import Companies from './modules/companies'
import Config from './modules/config'
import CostTypes from './modules/cost-types'
import Integrations from './modules/integrations'
import Mileage from './modules/mileage'
import Overview from './modules/overview'
import Transactions from './modules/transactions'
import Payments from './modules/payment-methods'
import _Receipts from './modules/receipts'
import Setting from './modules/setting'
import Trips from './modules/trips'
import Users from './modules/users'

import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import state from './state'

const debug = location.hostname === 'localhost' || location.hostname === '127.0.0.1'

export const xstore = createStore({
  mutations,
  actions,
  getters,
  state,
  modules: {
    Auth,
    Accounting,
    AccountingSettings,
    Approvals,
    ApprovalSettings,
    Companies,
    Config,
    CostTypes,
    Integrations,
    Mileage,
    Overview,
    Payments,
    Requests,
    _Receipts,
    Receipts,
    Setting,
    Trips,
    Transactions,
    Users
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})

export default xstore
