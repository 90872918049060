import Axios from 'axios'
import { PAYMENTS_STORE_ACTIONS, PAYMENTS_STORE_MUTATIONS } from '@/enums'
import { PaymentMethod } from '@/models'

export default {
  [PAYMENTS_STORE_ACTIONS.GET_PAYMENT_METHODS]: async ({ commit }: any, payload: { companyId: string | number }) => {
    try {
      const { companyId } = payload

      const { data: paymentMethods = [] } = await Axios.get(`@coreapi/companies/${companyId}/payment-methods`)

      commit(PAYMENTS_STORE_MUTATIONS.SET_PAYMENT_METHODS, { companyId, paymentMethods })

      return paymentMethods
    } catch (error) {
      throw error
    }
  },
  [PAYMENTS_STORE_ACTIONS.CREATE_PAYMENT_METHOD]: async (
    { commit }: any,
    payload: { companyId: string | number; newPaymentMethod: PaymentMethod }
  ) => {
    try {
      const { companyId, newPaymentMethod } = payload

      const { data: paymentMethod = {} } = await Axios.post(
        `@coreapi/companies/${companyId}/payment-methods`,
        newPaymentMethod
      )

      commit(PAYMENTS_STORE_MUTATIONS.SET_PAYMENT_METHODS, { companyId, paymentMethods: [paymentMethod] })

      return paymentMethod
    } catch (error) {
      throw error
    }
  }
}
