import { computed, watch, ref } from 'vue'
import { isWithinInterval, addDays } from 'date-fns'
import { xstore } from '@/store'
import {
  RECEIPTS_STORE_ACTIONS,
  TRIPS_STORE_ACTIONS,
  TRIPS_STORE_GETTERS,
  TRIP_STATUS_VALIDATOR,
  TRIP_TYPE,
  TRIP_DATES_CRITERIA
} from '@/enums'
import { useParamsContext, useActiveDates, useUser } from '@/composables'
import { Trip, Company, Receipts, Receipt } from '@/models'
import { validateStatus } from '@/lib/status'

export default function useTrips() {
  const paramsContext = useParamsContext()
  const { activeUser } = useUser()

  const onTripsChangeTargetFn = ref((id: number) => {})

  const trips = computed<any>(() => {
    return xstore.getters[TRIPS_STORE_GETTERS.TRIPS]
  })

  const allowances = computed<any>(() => {
    return xstore.getters[TRIPS_STORE_GETTERS.ALLOWANCE]
  })

  const tripAllowance = computed<any[]>(() => {
    if (!paramsContext.value?.tripId) return []
    return allowances.value[paramsContext.value.tripId] || []
  })

  const companyTrips = computed<any[]>(() => {
    return Object.values(trips.value).filter(({ companyId }: any) => {
      return companyId === paramsContext.value.companyId
    })
  })

  const tripsByPeriod = computed<Trip[]>(() => {
    const { activeDates, selectTripsBy } = useActiveDates()
    const { startDate, endDate } = activeDates.value
    const endDatePlusOneDay = addDays(new Date(endDate), 1)
    return companyTrips.value
      .filter(({ createdAt, startDate: tripStartDate, endDate: tripEndDate }: Trip) => {
        const createdWithinInterval =
          (selectTripsBy.value === TRIP_DATES_CRITERIA.ALL || selectTripsBy.value === TRIP_DATES_CRITERIA.CREATED_AT) &&
          isWithinInterval(new Date(createdAt), {
            start: new Date(startDate),
            end: new Date(endDatePlusOneDay)
          })
        const startWithinInterval =
          (selectTripsBy.value === TRIP_DATES_CRITERIA.ALL ||
            selectTripsBy.value === TRIP_DATES_CRITERIA.CREATED_FOR) &&
          tripStartDate &&
          isWithinInterval(new Date(tripStartDate), {
            start: new Date(startDate),
            end: new Date(endDatePlusOneDay)
          })
        const endWithinInterval =
          (selectTripsBy.value === TRIP_DATES_CRITERIA.ALL ||
            selectTripsBy.value === TRIP_DATES_CRITERIA.CREATED_FOR) &&
          tripEndDate &&
          isWithinInterval(new Date(tripEndDate), {
            start: new Date(startDate),
            end: new Date(endDatePlusOneDay)
          })
        return createdWithinInterval || startWithinInterval || endWithinInterval
      })
      .reverse()
  })

  const activeTrip = computed<Trip | undefined>(() => {
    return trips.value[paramsContext.value.tripId || '']
  })

  // User can select these trips for receipts
  const selectableTrips = computed<Trip[]>(() => {
    return companyTrips.value
      .filter(({ status, id }: Trip) => {
        return (
          !validateStatus(status, TRIP_STATUS_VALIDATOR.SENT) &&
          !validateStatus(status, TRIP_STATUS_VALIDATOR.BOUNCED) &&
          (!activeTrip?.value?.id || activeTrip?.value?.id != id)
        )
      })
      .filter(({ subtype }) => subtype !== TRIP_TYPE.MILEAGE)
      .reverse()
  })

  const previousTripNames = computed(() => {
    return companyTrips.value.filter(({ userId }) => userId === activeUser.value?.id).map(({ name }) => name)
  })

  const createNewTrip = async (companyId: string | number, tripPayload: any) => {
    try {
      return xstore.dispatch(TRIPS_STORE_ACTIONS.NEW_TRIP, { companyId, tripPayload })
    } catch (error) {
      throw error
    }
  }

  const editTrip = (company: Company, trip: Trip, values: any) => {
    return xstore.dispatch(TRIPS_STORE_ACTIONS.UPDATE_TRIP_VALUES, { company, trip, values })
  }

  const deleteTrip = async (
    company: Company,
    trip: Trip,
    receipts: Receipts,
    keepReceipts: Boolean
  ): Promise<Boolean> => {
    if (!company.id || !trip.id) return false

    try {
      const receiptIds = receipts.map((receipt: Receipt) => receipt.id)
      if (keepReceipts) {
        receiptIds.forEach((id: string) => {
          xstore.dispatch(RECEIPTS_STORE_ACTIONS.UPDATE_RECEIPT_DATA, {
            receiptId: id,
            companyId: company.id,
            values: { tripId: null }
          })
        })
      } else {
        const _ = await xstore.dispatch(RECEIPTS_STORE_ACTIONS.DELETE_RECEIPTS_MASS, { company, receipts: receiptIds })
      }
      const __ = await xstore.dispatch(TRIPS_STORE_ACTIONS.DELETE_TRIP, { company, trip })
      return true
    } catch (error) {
      throw error
    }
  }

  const loadTripAllowance = async (): Promise<Boolean> => {
    const { companyId, tripId } = paramsContext.value
    if (!companyId || !tripId) return false

    try {
      const _ = await xstore.dispatch(TRIPS_STORE_ACTIONS.GET_ALLOWANCE, { companyId, tripId })
      return true
    } catch (error) {
      throw error
    }
  }

  const loadTrip = (tripId: string | number, options = {}): Promise<Trip> => {
    return xstore.dispatch(TRIPS_STORE_ACTIONS.GET_TRIP, {
      companyId: paramsContext.value.companyId,
      tripId,
      options
    })
  }

  const loadPaymentCardTrips = () => {
    if (!paramsContext.value.companyId) return
    return xstore.dispatch(TRIPS_STORE_ACTIONS.GET_UNSUBMITTED_CARD_REPORTS, {
      params: {
        companyId: paramsContext.value.companyId
      }
    })
  }

  const asyncGet = async (id?: number): Promise<Trip[]> => {
    const { activeDates } = useActiveDates()
    const { startDate, endDate } = activeDates.value
    const trips = await xstore.dispatch(TRIPS_STORE_ACTIONS.GET_FILTERED_TRIPS, {
      params: {
        companyId: id ?? paramsContext.value.companyId,
        startDate,
        endDate
      }
    })

    return trips
  }

  const tripById = (tripId: number) => {
    return trips.value?.[tripId] || {}
  }

  const onTripChange = (targetFn = (id: number) => {}) => {
    onTripsChangeTargetFn.value = targetFn
  }

  watch(
    () => activeTrip.value?.id,
    (id, oldId) => {
      if (id !== undefined && id !== oldId) {
        onTripsChangeTargetFn.value(id)
      }
    }
  )

  const findOverlappingTrips = (
    startDate: Date,
    endDate: Date,
    reportTypes: TRIP_TYPE[],
    reportSubTypes: TRIP_TYPE[]
  ) => {
    const trips: Trip[] = []

    for (const report of companyTrips.value) {
      // firstly check if the report is the correct type
      if (
        (!reportTypes.length || reportTypes.includes(report.type)) &&
        (!reportSubTypes.length || reportSubTypes.includes(report.subtype))
      ) {
        // secondly check if target report start or end dates are in the company report report
        const firstStartInSecond = isWithinInterval(new Date(report.startDate), {
          start: new Date(startDate),
          end: new Date(endDate)
        })
        const firstEndInSecond = isWithinInterval(new Date(report.endDate), {
          start: new Date(startDate),
          end: new Date(endDate)
        })
        // thirdly check whether the company report start is in target report period
        // we dont have to check the end date, because for any overlapping case, two conditions are true. So we can eliminate one check randomly
        const secondStartInFirst = isWithinInterval(new Date(startDate), {
          start: new Date(report.startDate),
          end: new Date(report.endDate)
        })
        if (firstStartInSecond || firstEndInSecond || secondStartInFirst) {
          trips.push(report)
        }
      }
    }
    return trips
  }

  const findOverlappingPaymentTrips = (currentTrip: Trip) => {
    // find all matching trips
    const trips = findOverlappingTrips(
      currentTrip?.startDate,
      currentTrip?.endDate,
      [TRIP_TYPE.EXPENSE],
      [TRIP_TYPE.CREDIT_CARD, TRIP_TYPE.DEBIT_CARD]
    )

    // filter out trips that don't match the card id or have the same id (same report)
    return trips.filter((trip) => {
      const currentPaymentCardId = currentTrip?.paymentCard?.id || currentTrip?.extraData?.paymentMethodId
      const overlappingPaymentCardId = trip?.paymentCard?.id || trip?.extraData?.paymentMethodId

      const tripsMatch = trip?.id === currentTrip?.id
      const paymentCardsMatch =
        currentPaymentCardId && overlappingPaymentCardId && currentPaymentCardId === overlappingPaymentCardId

      return paymentCardsMatch && !tripsMatch
    })
  }

  return {
    trips,
    selectableTrips,
    companyTrips,
    tripAllowance,
    tripsByPeriod,
    activeTrip,
    previousTripNames,
    onTripChange,
    loadTrip,
    loadTripAllowance,
    loadPaymentCardTrips,
    createNewTrip,
    editTrip,
    deleteTrip,
    asyncGet,
    tripById,
    findOverlappingPaymentTrips
  }
}
