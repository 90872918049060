import UiSelectTransparent from '@ui/components/UiSelectTransparent.vue'
import UiNavigationDrawer from '@ui/components/UiNavigationDrawer.vue'
import UiImagePreview from '@ui/components/UiImagePreview.vue'
import UiInputChips from '@ui/components/UiInputChips.vue'
import UiDatepicker from '@ui/components/UiDatepicker.vue'
import UiFabButton from '@ui/components/UiFabButton.vue'
import UiPopupMenu from '@ui/components/UiPopupMenu.vue'
import UiSearchBar from '@ui/components/UiSearchBar.vue'
import UiCheckbox from '@ui/components/UiCheckbox.vue'
import UiInfoBox from '@ui/components/UiInfoBox.vue'
import UiSelect from '@ui/components/UiSelect.vue'
import UiSwitch from '@ui/components/UiSwitch.vue'
import UiButton from '@ui/components/UiButton.vue'
import UiButtonDesktop from '@ui/components/UiButtonDesktop.vue'
import UiButtonSwitch from '@ui/components/UiButtonSwitch.vue'
import UiFileUploadButton from '@ui/components/UiFileUploadButton.vue'
import UiLoader from '@ui/components/UiLoader.vue'
import UiInput from '@ui/components/UiInput.vue'
import UiModal from '@ui/components/UiModal.vue'
import UiHeader from '@ui/components/UiHeader.vue'
import UiTooltip from '@ui/components/UiTooltip.vue'
import UiCard from '@ui/components/UiCard.vue'

import UploadWarning from './UploadWarning.vue'
import Spinner from './Spinner'

export const registerStaticComponents = {
  install: (app) => {
    app.component('ui-select-transparent', UiSelectTransparent)
    app.component('ui-navigation-drawer', UiNavigationDrawer)
    app.component('ui-image-preview', UiImagePreview)
    app.component('ui-input-chips', UiInputChips)  
    app.component('ui-fab-button', UiFabButton)
    app.component('ui-popup-menu', UiPopupMenu)
    app.component('ui-search-bar', UiSearchBar)
    app.component('ui-checkbox', UiCheckbox)
    app.component('ui-info-box', UiInfoBox)
    app.component('ui-switch', UiSwitch)
    app.component('ui-select', UiSelect)
    app.component('ui-button', UiButton)
    app.component('ui-button-desktop', UiButtonDesktop)
    app.component('ui-button-switch', UiButtonSwitch)
    app.component('ui-file-upload-button', UiFileUploadButton)
    app.component('ui-header', UiHeader)
    app.component('ui-loader', UiLoader)
    app.component('ui-input', UiInput)
    app.component('ui-modal', UiModal)
    app.component('ui-card', UiCard)
    app.component('ui-tooltip', UiTooltip)
    app.component('upload-warning', UploadWarning)
    app.component('cp-spinner', Spinner)
  }
}

export const registerDynamicComponents = {
  install: (app) => {
    app.component('UiDatepicker', UiDatepicker)
  }
}