import Moment from 'moment'

import { useUser } from '.'

export default function useFormatters() {
  const { activeUser } = useUser()

  const formatLocale = (userLocale: string) => {
    switch (userLocale) {
      case 'est':
        return 'et'
      case 'lav':
        return 'lv'
      case 'fin':
        return 'fi'
      case 'rus':
        return 'ru'
      case 'swe':
        return 'sv'
      case 'lit':
        return 'lt'
      case 'esp':
        return 'es'
      case 'eng':
        return 'en'
      default:
        return 'en'
    }
  }

  const formatSum = (money: number | string) => {
    return Number(money).toFixed(2) || '0.00'
  }

  const formatInteger = (numericValue: number | string) => {
    if (!numericValue) return 0
    return Number(numericValue).toFixed(0)
  }

  const formatDate = (date: Date | string) => {
    const formattedDate = Moment(date).format('YYYY-MM-DD')
    return formattedDate !== 'Invalid date' ? formattedDate : ''
  }

  const formatDateShort = (date: Date | string) => {
    const formattedDate = Moment(date).format('DD.MM')
    return formattedDate !== 'Invalid date' ? formattedDate : ''
  }

  const formatDateLong = (date: Date | string) => {
    const formattedDate = Moment(date).format('DD.MM.YYYY')
    return formattedDate !== 'Invalid date' ? formattedDate : ''
  }

  const formatDateSlashes = (date: Date | string) => {
    const formattedDate = Moment(date).format('DD/MM/YYYY')
    return formattedDate !== 'Invalid date' ? formattedDate : ''
  }

  const formatDateDashes = (date: Date | string) => {
    const formattedDate = Moment(date).format('DD-MM-YYYY')
    return formattedDate !== 'Invalid date' ? formattedDate : ''
  }

  const formatDateTime = (date: Date | string) => {
    const formattedDate = Moment(date).locale(formatLocale(activeUser?.value?.lang)).format('LLL')
    return formattedDate !== 'Invalid date' ? formattedDate : ''
  }

  const formatMonthAndYear = (date: Date | string) => {
    const formattedDate = Moment(date).locale(formatLocale(activeUser?.value?.lang)).format('MMMM YYYY')
    return formattedDate !== 'Invalid date' ? formattedDate : ''
  }

  const formatDateTextShort = (date: Date | string) => {
    const formattedDate = Moment(date).locale(formatLocale(activeUser?.value?.lang)).format('ll')
    return formattedDate !== 'Invalid date' ? formattedDate : ''
  }

  const formatCurrency = (currency: string) => {
    if (!currency) return '' 
    type Currency = {
      [key: string]: string
    }

    const CURRENCY_SYMBOLS: Currency = {
      EUR: '€',
      GBP: '£',
      USD: '$',
      SEK: 'kr',
      NOK: 'kr',
      PLN: 'zł',
      RUB: '₽'
  }
    return CURRENCY_SYMBOLS[currency] || currency
  }

  const truncateText = (text: string, nrOfCharacters: number) => {
    if (!text) return ''
    if (text.length < nrOfCharacters) return text
    return `${text.substring(0, nrOfCharacters)}...`
  }

  return {
    formatSum,
    formatInteger,
    formatDate,
    formatDateShort,
    formatDateLong,
    formatDateTextShort,
    formatDateSlashes,
    formatDateDashes,
    formatDateTime,
    formatMonthAndYear,
    formatLocale,
    formatCurrency,
    truncateText
  }
}
