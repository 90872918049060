import { COST_TYPES_STORE_GETTERS, COST_TYPES_STORE_STATE } from '@/enums'
import { ReceiptsStoreState, ExpenseType, ExpenseTypeSubgroup } from '@/models'

export default {
  [COST_TYPES_STORE_GETTERS.EXPENSE_TYPES]: (state: ReceiptsStoreState): ExpenseType[] => {
    return state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] || []
  },
  [COST_TYPES_STORE_GETTERS.EXPENSE_TYPE_BY_ID]:
    (state: ReceiptsStoreState) =>
    (expenseTypeId: number): ExpenseType | undefined => {
      return (state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] || []).reduce(
        (accumulator: ExpenseType | undefined, expenseType: ExpenseType) => {
          const sub = expenseType?.subgroup?.find(({ id }: ExpenseTypeSubgroup) => id === expenseTypeId)

          if (sub) {
            accumulator = sub
          } else if (expenseType?.id === expenseTypeId) {
            accumulator = expenseType
          }

          return accumulator
        },
        undefined
      )
    }
}
