<template>
  <div class="search-bar-wrapper" :class="{ desktop, shadow: hasShadow }">
    <div
      class="search-bar-overlay"
      v-show="showPopover && suggestions && suggestions.length > 0 && !alwaysOpen"
      @click="onBackdropClick"
    ></div>

    <div
      class="search-bar"
      :class="{
        active: searchBarActive,
        invalid: !isValid
      }"
    >
      <div class="input-wrapper" :class="{ 'has-floating-label': floatingLabel, 'has-value': keyword }">
        <input
          ref="inputField"
          class="search-input"
          type="text"
          :value="keyword"
          :placeholder="floatingLabel ? '' : placeholder"
          @input="handleInput($event)"
          @focus="onFocus"
          @blur="onBlur"
          @keyup.enter="onEnter($event)"
          v-sanitize
        />
        <label v-if="floatingLabel" class="floating-label ellipsis">{{ placeholder }}</label>
      </div>

      <span class="icon-wrapper">
        <button v-if="keyword" @click="deleteInput(true)">
          <icon-cancel fill="#484e70" :height="iconSize" :width="iconSize"></icon-cancel>
        </button>
        <icon-search v-else fill="#484e70" :height="iconSize" :width="iconSize"></icon-search>
      </span>
    </div>

    <div class="search-popover" v-show="showPopover && suggestions && suggestions.length > 0">
      <div
        class="search-option"
        v-for="suggestion in suggestions"
        :class="{ focused: focusedOption === suggestion }"
        :key="suggestion.value"
        @click="selectSuggestion(suggestion)"
      >
        <p>{{ suggestion.title }}</p>
        <p v-if="suggestion.extraInfo" class="extra-info">{{ suggestion.extraInfo }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'

export default {
  name: 'UiSearchBar',
  emits: ['update:modelValue', 'selected', 'entered'],
  props: {
    modelValue: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    suggestions: {
      type: Array
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    alwaysOpen: {
      type: Boolean,
      default: false
    },
    desktop: {
      type: Boolean,
      default: false
    },
    hasShadow: {
      type: Boolean,
      default: false
    },
    floatingLabel: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const ENTER_KEY_CODE = 13
    const DOWN_ARROW_KEY_CODE = 40
    const UP_ARROW_KEY_CODE = 38
    const inputField = ref(null)
    const keyword = ref(props.modelValue || '')
    const showPopover = ref(false)
    const searchBarActive = ref(false)
    const iconSize = ref('24px')
    const focusedOption = ref('')

    onMounted(() => {
      if (props.autoFocus) {
        inputField.value.focus()
      }

      if (props.desktop) {
        iconSize.value = '18px'
      }
    })

    watch(
      () => props.modelValue,
      () => {
        keyword.value = props.modelValue
      }
    )

    watch(
      () => props.suggestions,
      () => {
        if (showPopover.value && props.suggestions?.length) {
          window.addEventListener('keyup', onKeyup)
        }
      }
    )

    function handleInput(event) {
      keyword.value = event.target.value
      emit('update:modelValue', keyword.value)
    }

    function onEnter(e) {
      emit('entered', e)
    }

    function deleteInput(focusInput) {
      keyword.value = ''
      emit('update:modelValue', keyword.value)
      if (focusInput) {
        inputField.value.focus()
      }
    }

    function selectSuggestion(selected) {
      keyword.value = selected.title
      emit('selected', selected)
      showPopover.value = false
    }

    function onFocus() {
      showPopover.value = true
      searchBarActive.value = true
      if (props.suggestions?.length) {
        window.addEventListener('keyup', onKeyup)
      }
    }

    function onBlur() {
      searchBarActive.value = false
      setTimeout(() => {
        window.removeEventListener('keyup', onKeyup)
      }, 100)
    }

    function onBackdropClick() {
      showPopover.value = false
    }

    function focusNextListItem(direction) {
      // Find current highlighted option
      let nextActiveElementIndex
      if (typeof focusedOption.value === 'string') {
        nextActiveElementIndex = props.suggestions.indexOf(focusedOption.value)
      } else {
        nextActiveElementIndex = props.suggestions.findIndex((option) => option?.value === focusedOption.value?.value)
      }

      // Select next option
      if (nextActiveElementIndex === -1) {
        nextActiveElementIndex = 0
      } else if (direction === DOWN_ARROW_KEY_CODE) {
        nextActiveElementIndex += 1
      } else if (direction === UP_ARROW_KEY_CODE) {
        nextActiveElementIndex -= 1
      }

      // Go from first to last and last to first option
      nextActiveElementIndex = (nextActiveElementIndex + props.suggestions.length) % props.suggestions.length
      focusedOption.value = props.suggestions[nextActiveElementIndex]
    }

    function onKeyup(event) {
      switch (event.keyCode) {
        case ENTER_KEY_CODE:
          selectSuggestion(focusedOption.value)
          return
        case DOWN_ARROW_KEY_CODE:
          focusNextListItem(DOWN_ARROW_KEY_CODE)
          return
        case UP_ARROW_KEY_CODE:
          focusNextListItem(UP_ARROW_KEY_CODE)
          return
        default:
          return
      }
    }

    return {
      handleInput,
      onEnter,
      deleteInput,
      selectSuggestion,
      onFocus,
      onBlur,
      onBackdropClick,
      inputField,
      keyword,
      showPopover,
      searchBarActive,
      iconSize,
      focusedOption
    }
  }
}
</script>

<style lang="scss" scoped>
.search-bar-wrapper {
  @extend %box-text;
  position: relative;
}

.search-bar-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 599;
}

.search-bar {
  @extend %box-outline;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 56px;
  padding: 0 16px;
  background: $white;
  // z-index: 600;

  &:hover {
    border: 1.5px solid $grey;
  }

  &.active {
    border: 2px solid $main-blue;
    color: $medium-dark-grey;
  }

  &.invalid {
    border: 2px solid $main-red;
  }

  .input-wrapper {
    padding: 15px 0;
    flex-grow: 1;
  }

  .input-wrapper.has-floating-label {
    padding: 24px 0 8px;
  }

  .icon-wrapper button {
    display: flex;
    align-items: center;
  }
}

.search-input {
  @extend %box-text;
  border: none;
  width: 100%;
  caret-color: $main-blue;
  color: $medium-dark-grey;

  &::placeholder {
    color: $grey;
  }

  &::-ms-input-placeholder {
    color: $grey;
  }
}

.floating-label {
  @extend %box-text;
  font-weight: 400;
  width: 90%;
  position: absolute;
  left: 17px;
  top: 16px;
  text-align: left;
  color: $grey;
  pointer-events: none;
  transition: all 200ms ease;
}

input:focus + .floating-label,
.has-value .floating-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  transform: translateY(-8px);
}

.icon-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.search-popover {
  @extend %box-outline;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  max-height: 50vh;
  width: 100%;
  top: 120%;
  background: $white;
  overflow: auto;
  @include scrollbar;
  z-index: 601;
}

@media (max-height: 500px) {
  .search-popover {
    max-height: 34vh;
  }
}

.search-option {
  padding: 16px;
  width: 100%;
  line-height: 16px;
  font-weight: 400;
  background: $white;
  cursor: pointer;

  .extra-info {
    font-size: 12px;
    color: $grey;
  }

  &:hover {
    background: $light-grey;
  }

  &.selected {
    background: $light-blue;
  }

  &.focused {
    background: $medium-light-grey;
  }
}

.shadow {
  .search-popover {
    box-shadow: $shadow-extended;
  }
}

// DESKTOP VIEW
.desktop {
  font-size: 12px;

  .search-input {
    font-size: 12px;
  }

  .search-bar {
    height: 40px;
    padding: 0 16px;
  }

  .input-wrapper {
    padding: 5px 0;
  }

  .search-option {
    padding: 8px 16px;
  }
}
</style>
