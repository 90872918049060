import translations from '@/lib/l10n'
import store from '@/store'

import { handleError } from '@/lib/handler'

import { USERS_STORE_GETTERS } from '@/enums'
import { computed, watch } from 'vue'

export function getLanguageISO3() {
  return store.getters[USERS_STORE_GETTERS.ACTIVE_USER]?.lang
}

export default {
  install: function (app: any) {
    const language = computed(() => {
      const stashUserLang = app.config.globalProperties?.$stash?.user?.lang
      const storeUserLang = getLanguageISO3()
      return stashUserLang || localStorage.language || storeUserLang || 'eng'
    })

    app.config.globalProperties.t = (languageKey: string, params: { [x: string]: any }, config = {}) => {
      if (!languageKey) return ''

      // @ts-ignore
      let foundTranslation =
      // @ts-ignore      
        translations[languageKey]?.[language.value] || translations[languageKey]?.eng || languageKey

      if (!foundTranslation) {
        handleError('Missing translation for key "' + languageKey + '"')

        return languageKey
      }

      if (params && foundTranslation) {
        Object.keys(params).forEach((key) => {
          foundTranslation = foundTranslation.replace('$' + key, params[key])
        })
      }

      return foundTranslation
    }
  }
}
