export enum USERS_STORE_STATE {
  ACTIVE_USER = 'Users/ACTIVE_USER'
}

export enum USERS_STORE_MUTATIONS {
  SET_USER = 'Users/SET_USER'
}

export enum USERS_STORE_ACTIONS {
  GET_USER = 'Users/GET_USER',
  UPDATE_USER = 'Users/UPDATE_USER'
}

export enum USERS_STORE_GETTERS {
  ACTIVE_USER = 'Users/ACTIVE_USER',
}