import {
  STORE_MUTATIONS,
  STORE_STATE
} from '@/enums'

import {
  StoreCancelToken,
  StoreState
} from '@/models'

export default {
  [STORE_MUTATIONS.SET_STATE] (
    state: StoreState,
    newState: any
  ) {
    state = newState
  },
  [STORE_MUTATIONS.SET_CANCEL_TOKEN] (
    state: StoreState,
    storeCancelToken: StoreCancelToken
  ) {
    state[STORE_STATE.CANCEL_TOKENS].push(storeCancelToken)
  },
  [STORE_MUTATIONS.REMOVE_CANCEL_TOKEN] (
    state: StoreState,
    target: StoreCancelToken['target']
  ) {
    state[STORE_STATE.CANCEL_TOKENS] = state[STORE_STATE.CANCEL_TOKENS]
      .filter(({ target: _target }) => _target !== target)
  },
  [STORE_MUTATIONS.SET_LOCALSTORE] (
    state: StoreState,
    data: any
  ) {
    state[STORE_STATE.LOCALSTORAGE] = data
  }
}
