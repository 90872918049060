import {
  USERS_STORE_MUTATIONS,
  USERS_STORE_STATE
} from '@/enums'

import {
  UsersStoreState
} from '@/models'

export default {
  [USERS_STORE_MUTATIONS.SET_USER]: (state: UsersStoreState, user: any) => {
    state[USERS_STORE_STATE.ACTIVE_USER] = user
  }
}