export enum INTEGRATION_CONFIRM_ERROR {
  SERVICE_CONFIRMATION_CODE_INVALID = 'invalidConfirmCode',
  SERVICE_CONFIRMATION_FAILURE = 'confirmActionFailed',
  SERVICE_CONFIRMATION_CODE_USED = 'confirmActionDone'
}

export enum PREVALIDATION_ERROR_MESSAGES {
  NON_ZERO = 'nonZero',
  NON_ZERO_COUNT = 'nonZeroCount',
  GREATER_THAN_ZERO = 'greaterThanZero',
  MUST_EXIST = 'mustExist'
}

export enum ERRROR_MESSAGES {
  DOC_NOT_DIGITIZED = 'efExDocNotDigitized',
  DOC_PENDING_APPROVAL = 'efExDocPendingApproval',
  REPORT_PENDING_APPORVAL = 'efExReportPendingApproval',
  REPORTS_NOT_ENABLED = 'efExReportsNotEnabled',
  DOC_NOT_SUBMITTED = 'efExDocNotSubmitted',
  REPORT_NOT_SUBMITTED = 'efExReportNotSubmitted',
  DOC_NOT_APPROVED = 'efExDocNotApproved',
  REPORT_NOT_APPROVED = 'efExReportNotApproved',
  DOC_PART_OF_REPORT = 'efExDocPartOfReport',
  SENDING_DOCS_NOT_ALLOWED = 'efExSendingDocsNotAllowed',
  UNSUBMITTING_NOT_ALLOWED = 'efExUnsubmittingNotAllowed',
  DOC_NOT_DISTINCT = 'efExDocNotDistinct',
  DOC_NOT_READY = 'pendingConfirmation',
  ALREADY_SENT_TO_ACCOUNTING = 'efExDocAlreadyAccounting',
  DOC_ALREADY_IMPORTED = 'docAlreadyImported',
  ACCESS_NOT_ALLOWED = 'accessRequestSent',
  CURRENCY_CONVERSION_SOURCE_DOES_NOT_SUPPORT_THIS_CURRENCY = 'currencyNotSupported'
}
