import { USERS_STORE_ACTIONS, USERS_STORE_MUTATIONS } from '@/enums'

import Axios from 'axios'
import errorHandler from '@/lib/errorHandler'

export default {
  [USERS_STORE_ACTIONS.GET_USER]: async ({ commit }: any) => {
    try {
      const { data = {} } = await Axios.get('@coreapi/user')
      commit(USERS_STORE_MUTATIONS.SET_USER, data)

      return data
    } catch (error: any) {
      errorHandler(USERS_STORE_ACTIONS.GET_USER, error)

      // clear auth state
      localStorage.removeItem('token')
    }
  },
  [USERS_STORE_ACTIONS.UPDATE_USER]: async ({ commit }: any, payload: any = {}) => {
    try {
      const { data = {} } = await Axios.put('@coreapi/user/edit', payload)
      commit(USERS_STORE_MUTATIONS.SET_USER, data)

      return data
    } catch (error: any) {
      throw error
    }
  }
}
