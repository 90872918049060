import { computed } from 'vue'
import { USERS_STORE_GETTERS, USERS_STORE_ACTIONS } from '@/enums'
import { xstore } from '@/store'
import { User } from '@/models'
import { useContext } from '@/composables'

export default function useUser() {
  const { root } = useContext()

  const activeUser = computed<User>(() => {
    return xstore.getters[USERS_STORE_GETTERS.ACTIVE_USER]
  })

  const changeUserLanguage = async (language: string) => {
    try {
      localStorage.setItem('language', language)
      await xstore.dispatch(USERS_STORE_ACTIONS.UPDATE_USER, { lang: language })
      root?.$notification('saved', 'success', 3000)
    } catch (error) {
      root?.$notification(root.eh(error), 'error', 9000)
    }
  }

  return {
    activeUser,
    changeUserLanguage
  }
}
