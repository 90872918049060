import {
  USERS_STORE_GETTERS,
  USERS_STORE_STATE,
} from '@/enums'

import {
  UsersStoreState
} from '@/models'

export default {
  [USERS_STORE_GETTERS.ACTIVE_USER]: (state: UsersStoreState) => {
    return state[USERS_STORE_STATE.ACTIVE_USER]
  }
}