import {
  STORE_STATE_LOCAL_KEY,
  STORE_STATE
} from '@/enums'

export default () => ({
  [STORE_STATE.CANCEL_TOKENS]: [],
  [STORE_STATE.LOCALSTORAGE]: {
    [STORE_STATE_LOCAL_KEY.FILTERS]: []
  }
})
