import mutations from './users.mutations'
import actions from './users.actions'
import getters from './users.getters'
import state from './users.state'

export default {
  mutations,
  actions,
  getters,
  state
}
