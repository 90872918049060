import Axios from 'axios'
import { useReceiptUpload } from '@/app-composables'
import { useContext } from '@/composables'

export default function useCardReports() {
  const { alertAllowAccess } = useReceiptUpload()
  const { root } = useContext()

  const uploadStatement = (e: Event, companyId: any, tripId: any, paymentMethodId: any) => {
    const formData = new FormData()
    const target = e.target as HTMLInputElement
    const files = target.files
    const statementFile = files ? files[0] : null
    if (statementFile) {
      formData.append('file', statementFile)
    }

    return Axios.post(`@coreapi/companies/${companyId}/card-reports/${tripId}/statement`, formData, {
      params: {
        paymentMethodId
      }
    }).then(({ data: paymentTransactionsStatement = {} }) => {
      console.log("PAYMENT TRANSACTION STATEMENT", paymentTransactionsStatement)
      return paymentTransactionsStatement
    })
  }

  // MOBILE
  const uploadStatementX = async (e: Event, companyId: any, tripId: any, paymentMethodId: any) => {
    const fileName: string = await new Promise((resolve, reject) => {
      // @ts-ignore
      xcall('openGallery', (allowed: any, result: any) => {
        if (!allowed) {
          alertAllowAccess('gallery')
          reject('')
        }

        if (!result) {
          reject('')
        }

        // result ~ { ImageName: 'original image name', FileName: 'filename where it is saved in the app' }
        resolve(result.FileName)
      })
    })

    var {data} = await Axios.get('/data/' + fileName, {responseType: 'arraybuffer'})

    var formData = new FormData()
    var blob = new Blob([data])

    formData.append('file', blob, fileName)

    return Axios.post(`@coreapi/companies/${companyId}/card-reports/${tripId}/statement`, formData, {
      params: {
        paymentMethodId
      }
    }).then(({ data: paymentTransactionsStatement = {} }) => {
      return paymentTransactionsStatement
    })
  }

  const getStatement = (companyId: any, tripId: any) => {
    return Axios.get(`@coreapi/companies/${companyId}/card-reports/${tripId}/statement`).then(
      ({ data: paymentTransactionsStatement = {} }) => paymentTransactionsStatement
    )
  }

  const deleteStatement = (companyId: any, tripId: any) => {
    return Axios.delete(`@coreapi/companies/${companyId}/card-reports/${tripId}/statement`)
  }

  const automatchTransactions = (companyId: any, tripId: any) => {
    return Axios.post(`@coreapi/companies/${companyId}/card-reports/${tripId}/automatch`).then(
      ({ data: paymentTransactions = [] }) => paymentTransactions
    )
  }

  const matchDocumentToTransaction = (companyId: any, receiptId: any, transactionId: any) => {
    return Axios.post(`@coreapi/companies/${companyId}/payment-transactions/${transactionId}/match/${receiptId}`).then(
      ({ data = {} }) => data
    )
  }

  return {
    matchDocumentToTransaction,
    automatchTransactions,
    uploadStatement,
    uploadStatementX,
    getStatement,
    deleteStatement
  }
}
