import { computed } from 'vue'
import Axios from 'axios'
import xstore from '@/store'
import { BankFormat, Receipt, Trip, PaymentTransaction } from '@/models'
import {
  TRIPS_STORE_ACTIONS,
  TRIPS_STORE_GETTERS,
  TRIPS_STORE_MUTATIONS,
  RECEIPTS_STORE_MUTATIONS,
  ITEM_ROW_KEY
} from '@/enums'
import { useCardReports } from '@/app-composables'
import { useContext, useCompanies, useUser, useReceipts, useItemRows, useExpenseFlow } from '@/composables'

export default function useCardReconciliation() {
  const { root } = useContext()
  const { activeCompany } = useCompanies()
  const { activeUser } = useUser()
  const { uploadStatement } = useCardReports()
  const { createReceipt } = useReceipts()
  const { addItemRow, updateItemRow } = useItemRows()
  const { submitReceipt } = useExpenseFlow()

  const tripId = computed(() => {
    return root?.$route?.params?.tripId?.toString() || ''
  })

  const companyId = computed(() => {
    return root?.$route.params.companyId
  })

  const statements = computed(() => {
    return xstore.getters[TRIPS_STORE_GETTERS.STATEMENT]
  })

  const supportedStatementFormats = computed(() => {
    const allBankStatementFormats = xstore.getters[TRIPS_STORE_GETTERS.STATEMENT_FORMATS]
    return allBankStatementFormats.filter(({ country }: BankFormat) => country === activeCompany.value?.countryCode)
  })

  const activeTripStatement = computed(() => {
    return statements.value?.[Number(tripId.value)] || {}
  })

  const loadBankStatement = (companyId: any, tripId: any) => {
    return xstore.dispatch(TRIPS_STORE_ACTIONS.GET_STATEMENT, { companyId, tripId })
  }

  const loadStatementFormats = (companyId: any) => {
    return xstore.dispatch(TRIPS_STORE_ACTIONS.GET_STATEMENT_FORMATS, { companyId })
  }

  const removeStatement = (companyId: any, tripId: any) => {
    return xstore.dispatch(TRIPS_STORE_ACTIONS.DELETE_STATEMENT, { companyId, tripId })
  }

  const automatchTransactions = (companyId: any, tripId: any) => {
    return Axios.post(`@coreapi/companies/${companyId}/card-reports/${tripId}/automatch`).then(
      ({ data: transactions = [] }) => {
        transactions.forEach((transaction: PaymentTransaction) => {
          xstore.commit(TRIPS_STORE_MUTATIONS.SET_STATEMENT_TRANSACTION, { tripId, transaction })
        })
        return transactions
      }
    )
  }

  const uploadBankStatement = async (event: any, paymentMethodId: any) => {
    try {
      const statement = await uploadStatement(event, companyId.value, tripId.value, paymentMethodId)
      const transactions = await automatchTransactions(companyId.value, tripId.value)
      statement.transactions = transactions
      xstore.commit(TRIPS_STORE_MUTATIONS.SET_STATEMENT, { tripId: tripId.value, statement })
      xstore.commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPTS, [statement])
      return statement
    } catch (error) {
      root?.$notification(root.eh(error), 'error', 9000)
      throw error
    }
  }

  const matchDocumentToTransaction = (companyId: any, receiptId: any, transactionId: any, tripId: any) => {
    return Axios.post(`@coreapi/companies/${companyId}/payment-transactions/${transactionId}/match/${receiptId}`).then(
      ({ data: transaction = {} }) => {
        xstore.commit(TRIPS_STORE_MUTATIONS.SET_STATEMENT_TRANSACTION, { tripId, transaction })
        return transaction
      }
    )
  }

  const unmatchDocumentFromTransaction = (companyId: any, receiptId: any, transactionId: any, tripId: any) => {
    return Axios.post(
      `@coreapi/companies/${companyId}/payment-transactions/${transactionId}/unmatch/${receiptId}`
    ).then(({ data: transaction = {} }) => {
      xstore.commit(TRIPS_STORE_MUTATIONS.SET_STATEMENT_TRANSACTION, { tripId, transaction })
      return transaction
    })
  }

  const createDocsForUnmatchedTransactions = async (
    trip: Trip,
    transactions: PaymentTransaction[],
    receipts: Receipt[]
  ) => {
    if (!transactions) return
    for (const transaction of transactions) {
      if (!transaction.receiptId || !receipts.find(({ id }) => id === transaction.receiptId)) {
        const payload: any = {
          tripId: trip.id,
          extraData: {
            emptyReceipt: true
          },
          billingType: 'company',
          paymentMethodId: activeTripStatement.value?.paymentMethodId,
          issued: transaction.date,
          comment: transaction.comment,
          currency: transaction.currency
        }
        if (activeUser.value?.id !== trip.userId && trip.userId) {
          payload.userId = trip.userId
        }
        const createdReceipt = await createReceipt(companyId.value, payload)
        if (activeCompany.value && createdReceipt) {
          const response = await addItemRow(activeCompany.value, createdReceipt)
          if (response?.itemRows?.[0]) {
            await updateItemRow(
              activeCompany.value.id,
              createdReceipt,
              ITEM_ROW_KEY.TOTAL,
              transaction.sum,
              response.itemRows[0].rowNum
            )
          }
          await submitReceipt(activeCompany.value, createdReceipt)
          await matchDocumentToTransaction(companyId.value, createdReceipt.id, transaction.id, trip.id)
        }
      }
    }
    return true
  }

  return {
    activeTripStatement,
    supportedStatementFormats,
    automatchTransactions,
    createDocsForUnmatchedTransactions,
    loadBankStatement,
    loadStatementFormats,
    matchDocumentToTransaction,
    removeStatement,
    uploadBankStatement,
    unmatchDocumentFromTransaction
  }
}
