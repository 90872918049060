import { computed, watch, ref } from 'vue'
import Axios from 'axios'
import { capitalize } from 'lodash-es'
import { isWithinInterval, addDays } from 'date-fns'
import { xstore } from '@/store'
import { useContext, useActiveDates, useCompanies, useUser } from '@/composables'
import { validateStatus } from '@/lib/status'
import {
  RECEIPTS_STORE_ACTIONS,
  RECEIPTS_STORE_GETTERS,
  RECEIPTS_STORE_MUTATIONS,
  RECEIPT_DATES_CRITERIA,
  DOCUMENT_SUBTYPE,
  RECEIPT_STATUS
} from '@/enums'
import { Receipt, ExpenseType, Company, Receipts } from '@/models'

export default function useReceipts() {
  const { root } = useContext()

  const companyId = computed(() => Number(root?.$route.params.companyId))
  const receiptId = computed(() => Number(root?.$route.params.receiptId))
  const tripId = computed(() => Number(root?.$route.params.tripId))

  const onReceiptsChangeTargetFn = ref((id: number) => {})

  const receipts = computed<Receipts>(() => {
    return xstore.getters[RECEIPTS_STORE_GETTERS.RECEIPTS]
  })

  const activeReceipt = computed<Receipt | undefined>(() => {
    return receipts.value[receiptId.value || '']
  })

  const companyReceipts = computed<Receipt[]>(() => {
    return Object.values(receipts.value).filter((receipt: Receipt) => {
      return receipt.companyId === companyId.value
    })
  })

  const activeTripReceipts = computed<Receipt[]>(() => {
    return companyReceipts.value
      .filter((receipt: Receipt) => {
        return receipt.tripId === tripId.value
      })
      .reverse()
  })

  const activeCardTripExpenses = computed(() => {
    return activeTripReceipts.value?.filter((receipt) => receipt.docSubType !== DOCUMENT_SUBTYPE.CARD_STATEMENT) || []
  })

  const receiptsByPeriod = computed<Receipt[]>(() => {
    const { activeDates, selectReceiptsBy } = useActiveDates()
    const { startDate, endDate } = activeDates.value
    const { activeUserIsAdmin } = useCompanies()
    const { activeUser } = useUser()
    const endDatePlusOneDay = addDays(new Date(endDate), 1)
    return companyReceipts.value
      .filter(({ tripId }: Receipt) => !tripId)
      .filter((receipt) => (activeUserIsAdmin.value ? receipt : receipt.userId === activeUser.value?.id))
      .filter(({ createdAt, issued }: Receipt) => {
        const filterDate = selectReceiptsBy.value === RECEIPT_DATES_CRITERIA.CREATED ? createdAt : issued
        if (filterDate) {
          return isWithinInterval(new Date(filterDate), {
            start: new Date(startDate),
            end: new Date(selectReceiptsBy.value === RECEIPT_DATES_CRITERIA.CREATED ? endDatePlusOneDay : endDate)
          })
        }
        return false
      })
      .reverse()
  })

  const receiptById = (id: number | string): Receipt => {
    return receipts.value[Number(id) || ''] || {}
  }

  const loadReceipt = async (receiptId: string | number) => {
    try {
      const receipt = await xstore.dispatch(RECEIPTS_STORE_ACTIONS.GET_RECEIPT, {
        companyId: companyId.value,
        receiptId
      })
      return receipt
    } catch (error) {
      throw error
    }
  }

  const asyncGet = async (id?: number, tripId?: number | string): Promise<Receipt[]> => {
    const { activeDates, selectReceiptsBy } = useActiveDates()
    const { startDate, endDate } = activeDates.value
    const params: any = {
      selectBy: selectReceiptsBy.value === RECEIPT_DATES_CRITERIA.CREATED ? 'createdAt' : 'issued',
      companyId: id ?? companyId.value,
      tripId
    }
    if (!tripId) {
      params.startDate = startDate
      params.endDate = endDate
    }
    const receipts = await xstore.dispatch(RECEIPTS_STORE_ACTIONS.GET_FILTERED_RECEIPTS, { params })
    return receipts
  }

  const onReceiptChange = (targetFn = (id: number) => {}) => {
    onReceiptsChangeTargetFn.value = targetFn
  }

  const createReceipt = (companyId: any, values: any) => {
    return xstore.dispatch(RECEIPTS_STORE_ACTIONS.CREATE_RECEIPT, {
      companyId,
      values
    })
  }

  const updateReceipt = async (comapny: Company, receipt: Receipt, values: any): Promise<Receipt | undefined> => {
    try {
      if (!comapny?.id || !receipt?.id) return
      const updatedReceipt = await xstore.dispatch(RECEIPTS_STORE_ACTIONS.UPDATE_RECEIPT_DATA, {
        companyId: comapny.id,
        receiptId: receipt.id,
        values
      })
      return updatedReceipt
    } catch (error) {
      throw error
    }
  }

  const updateReceiptStore = (receipt: Receipt, values: any) => {
    xstore.commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, { receipt, values })
  }

  const deleteReceipt = async (company: Company, receipt: Receipt): Promise<Boolean> => {
    try {
      if (!company.id || !receipt.id) return false

      const _ = await xstore.dispatch(RECEIPTS_STORE_ACTIONS.DELETE_RECEIPT, { company, receipt })

      return true
    } catch (error) {
      throw error
    }
  }

  const deleteManyReceipts = async (company: Company, receiptIds: Array<string | number>): Promise<Boolean> => {
    try {
      if (!company.id) return false

      const _ = await xstore.dispatch(RECEIPTS_STORE_ACTIONS.DELETE_RECEIPTS_MASS, { company, receiptIds })

      return true
    } catch (error) {
      throw error
    }
  }

  const loadOCR = async (receipt: Receipt) => {
    const { ocr } = await xstore.dispatch(RECEIPTS_STORE_ACTIONS.GET_OCR, {
      receipt,
      companyId: companyId.value
    })
    if (ocr) {
      xstore.commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, {
        receipt: receipt,
        values: {
          ocr
        }
      })
    }
    return ocr
  }

  watch(
    () => activeReceipt.value?.id,
    (id, oldId) => {
      if (id !== undefined && id !== oldId) {
        onReceiptsChangeTargetFn.value(id)
      }
    }
  )

  const convertCurrency = async (companyId: any, receiptId: any, payload: any) => {
    return Axios.put(`@coreapi/companies/${companyId}/receipts/${receiptId}/currency`, payload).then(
      async ({ data = {} }) => {
        await loadReceipt(receiptId)
        if (!data.didConvert) {
          throw new Error('conversionNotSuccessful')
        }
      }
    )
  }

  const checkReceiptFileExistance = (receipt: Receipt) => {
    return (
      receipt?.extraData?.emptyReceipt ||
      receipt?.noPicture ||
      (receipt?.status && !validateStatus(receipt?.status, [RECEIPT_STATUS.FILE_RECEIVED]))
    )
  }

  return {
    onReceiptChange,
    companyReceipts,
    activeTripReceipts,
    activeReceipt,
    receiptsByPeriod,
    loadReceipt,
    loadOCR,
    receiptById,
    asyncGet,
    createReceipt,
    updateReceipt,
    updateReceiptStore,
    deleteReceipt,
    deleteManyReceipts,
    convertCurrency,
    activeCardTripExpenses,
    checkReceiptFileExistance
  }
}
