import {
  STORE_GETTERS,
  STORE_STATE
} from '@/enums'

import {
  StoreState
} from '@/models'

export default {
  [STORE_GETTERS.LOCALSTORAGE]: (state: StoreState) => state[STORE_STATE.LOCALSTORAGE]
}
