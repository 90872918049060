/* eslint-disable no-unused-vars */
import { makeURL } from '@/lib/vue-instance-helpers'
import { xstore } from '@/store'
import Axios from 'axios'
import Raven from 'raven-js'
import moment from 'moment'
import { Receipt } from '@/models'
import { CONFIG_STORE_MUTATIONS, CURRENCY, ERRROR_MESSAGES } from '@/enums'
import sanitize from '@ui/directives/sanitize'
import { App } from 'vue'

//@ts-ignore
function getUrl(url: string, basenames = Axios.defaults.__basenames, defaultIfMissing = 'https://api.costpocket.com') {
  return url.replace(/^@\w+/, (string) => (basenames && basenames[string] ? basenames[string] : defaultIfMissing))
}

export default {
  install: (app: App<Element>, config: any) => {
    if (config) {
      xstore.commit(CONFIG_STORE_MUTATIONS.SET_CONFIG, config)
    }

    app.config.globalProperties.$moment = moment
    app.config.globalProperties.$makeURL = makeURL
    app.config.globalProperties.$getUrl = getUrl

    app.config.globalProperties.$thumbnail = ({ companyId, updatedAt, id }: Receipt) => {
      if (!companyId || !id) return
      return getUrl(
        `@coreapi/companies/${companyId}/receipts/${id}/image/thumbnail?access_token=${localStorage.token}&cache=${
          updatedAt || ''
        }`
      )
    }

    app.config.globalProperties.$nonAdminThumbnail = (companyId: number, receiptId: number, approvalId: number) => {
      return getUrl(
        `@coreapi/companies/${companyId}/approvals/${approvalId}/doc/${receiptId}/image?access_token=${localStorage.token}`
      )
    }

    app.config.globalProperties.$original = ({ companyId, updatedAt, id }: Receipt) => {
      if (!companyId || !updatedAt || !id) return
      return getUrl(
        `@coreapi/companies/${companyId}/receipts/${id}/image/thumbnail?type=original&access_token=${localStorage.token}&cache=${updatedAt}`
      )
    }

    app.config.globalProperties.$pdf = ({ companyId, updatedAt, id }: Receipt) => {
      if (!companyId || !updatedAt || !id) return
      return getUrl(
        `@coreapi/companies/${companyId}/receipts/${id}/file/pdf?access_token=${localStorage.token}&cache=${updatedAt}`
      )
    }

    app.config.globalProperties.$date = (date: moment.MomentInput, format = 'DD.MM') => {
      return moment.utc(date).format(format)
    }

    app.config.globalProperties.n = (num: any, currency: CURRENCY = CURRENCY.EUR, spacer = '') => {
      const target = {
        [CURRENCY.EUR]: '€',
        [CURRENCY.GBP]: '£',
        [CURRENCY.USD]: '$'
      }

      const sign = target[currency] || target[CURRENCY.EUR]

      return `${num}${spacer}${sign}`
    }

    app.config.globalProperties.$setLoggingContext = (context: any) => {
      if (context?.user?.id) {
        Raven.setUserContext({ id: context.user.id })
      }
    }

    app.config.globalProperties.$captureException = (error: unknown) => {
      Raven.captureException(error)
    }

    app.config.globalProperties.eh = (error: any = {}) => {
      const error1 = ERRROR_MESSAGES[error?.response?.data?.codename as keyof typeof ERRROR_MESSAGES]
      const error2 = ERRROR_MESSAGES[error?.response?.data?.efException as keyof typeof ERRROR_MESSAGES]
      const error3 = ERRROR_MESSAGES[error?.efException as keyof typeof ERRROR_MESSAGES]
      const error4 = ERRROR_MESSAGES[error?.message as keyof typeof ERRROR_MESSAGES]
      const error5 = ERRROR_MESSAGES[error?.response?.data?.message as keyof typeof ERRROR_MESSAGES]
      const error6 = ERRROR_MESSAGES[error as keyof typeof ERRROR_MESSAGES]

      const errorFinal = error1 || error2 || error3 || error4 || error5 || error6 || false

      if (error?.response?.status === 403) {
        return 'isufficientAccessRights'
      } else if (error?.response?.data?.invalidFields?.[0]?.expected === 'Issued date must not be in the future!') {
        return 'issueDateNotInFuture'
      } else if (errorFinal) {
        return errorFinal
      } else if (error?.response?.data?.message) {
        return error.response.data.message
      } else if (error) {
        return error
      } else {
        return 'somethingWentWrong'
      }
    }

    app.directive('sanitize', sanitize)

    app.mixin({
      methods: {
        $moment: app.config.globalProperties.$moment,
        $makeURL: app.config.globalProperties.$makeURL,
        $getUrl: app.config.globalProperties.$getUrl,
        $thumbnail: app.config.globalProperties.$thumbnail,
        $nonAdminThumbnail: app.config.globalProperties.$nonAdminThumbnail,
        $original: app.config.globalProperties.$original,
        $pdf: app.config.globalProperties.$pdf,
        $date: app.config.globalProperties.$date,
        $setLoggingContext: app.config.globalProperties.$setLoggingContext,
        $captureException: app.config.globalProperties.$captureException,
        n: app.config.globalProperties.n,
        eh: app.config.globalProperties.eh
      }
    })
  }
}
