import { computed, ComputedRef, ref, Ref } from 'vue'
import { cloneDeep } from 'lodash-es'
import xstore from '@/store'
import { AccountingField, AccountingObject, AutoaccountingRule, Integration } from '@/models'
import { useContext } from '@/composables'
import {
  ACCOUNTING_OBJECT_TYPE,
  ACCOUNTING_STORE_ACTIONS,
  ACCOUNTING_STORE_GETTERS,
  INTEGRATIONS_STORE_GETTERS,
  REFERENCE_OBJECT_TYPE
} from '@/enums'

export default function useAccountingRefs() {
  const { root } = useContext()

  const referenceObjectTypes: Ref<Array<ACCOUNTING_OBJECT_TYPE>> = ref([
    ACCOUNTING_OBJECT_TYPE.ACCOUNT,
    ACCOUNTING_OBJECT_TYPE.ITEM_CODE,
    ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE,
    ACCOUNTING_OBJECT_TYPE.DIMENSION,
    ACCOUNTING_OBJECT_TYPE.DEPARTMENT,
    ACCOUNTING_OBJECT_TYPE.PROJECT,
    ACCOUNTING_OBJECT_TYPE.VAT_CODE
  ])

  const companyId = computed(() => Number(root?.$route.params.companyId))

  const companyAccountingObjects: ComputedRef<AccountingObject[]> = computed(() => {
    return xstore.getters[ACCOUNTING_STORE_GETTERS.ACCOUNTING_OBJECTS][companyId.value] || []
  })

  const activeIntegration: ComputedRef<Integration> = computed(() => {
    return xstore.getters[INTEGRATIONS_STORE_GETTERS.ACTIVE_INTEGRATION]
  })

  const activeAccountingFields: ComputedRef<AccountingField[] | undefined> = computed(() => {
    return activeIntegration.value?.configuration?.accountingFields
  })

  const availableAccountingFields: ComputedRef<AccountingField[] | undefined> = computed(() => {
    return activeIntegration.value?.configuration?.availableAccountingFields
  })

  const accountingEntriesEnabled: ComputedRef<boolean> = computed(() => {
    return Boolean(activeIntegration.value?.configuration?.accountingEntriesEnabled)
  })

  const autoAccountingSyncEnabled: ComputedRef<boolean> = computed(() => {
    return Boolean(activeIntegration.value?.configuration?.autoAccountingSyncEnabled)
  })

  const autoaccountingRules: ComputedRef<AutoaccountingRule[]> = computed(() => {
    return xstore.getters[ACCOUNTING_STORE_GETTERS.AUTOACCOUNTING_RULES][companyId.value]
  })

  const accountingObjectsByType = (objectType: ACCOUNTING_OBJECT_TYPE) => {
    return companyAccountingObjects.value.filter(({ type }) => type === objectType)
  }

  const loadAccountingObjects = async () => {
    if (!companyId.value) return
    return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.GET_ACCOUNTING_OBJECTS, { companyId: companyId.value })
  }

  const loadAutoaccountingRules = async () => {
    if (!companyId.value || !activeIntegration.value?.id) return
    return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.GET_AUTOMATION_RULES, {
      companyId: companyId.value,
      integrationId: activeIntegration.value.id
    })
  }

  const downloadContextTemplate = async (lang: string) => {
    if (!companyId.value || !activeIntegration.value?.id) return
    return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.DOWNLOAD_CONTEXT_TEMPLATE, {
      companyId: companyId.value,
      integrationId: activeIntegration.value.id,
      lang
    })
  }

  const uploadContextFile = async (accountingObjectType: ACCOUNTING_OBJECT_TYPE, e: Event) => {
    const formData = new FormData()
    const target = e.target as HTMLInputElement
    const files = target.files
    const uploadedFile = files ? files[0] : null
    if (uploadedFile) {
      formData.append('file', uploadedFile)
    }
    return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.UPDATE_CONTEXT_FROM_CSV, {
      companyId: companyId.value,
      integrationId: activeIntegration.value?.id,
      accountingObjectType,
      formData
    })
  }

  const createAccountingObject = (newAccountingObject: AccountingObject) => {
    if (!companyId.value) return
    return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.CREATE_ACCOUNTING_OBJECT, {
      companyId: companyId.value,
      newAccountingObject
    })
  }

  const updateAccountingObject = (updatedAccountingObject: AccountingObject) => {
    if (!companyId.value) return
    return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.UPDATE_ACCOUNTING_OBJECT, {
      companyId: companyId.value,
      updatedAccountingObject
    })
  }

  const deleteAccountingObject = (accountingObjectId: string | number) => {
    if (!companyId.value || !accountingObjectId) return
    return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.DELETE_ACCOUNTING_OBJECT, {
      companyId: companyId.value,
      accountingObjectId
    })
  }

  const updateAccountingFields = (target: REFERENCE_OBJECT_TYPE, type: ACCOUNTING_OBJECT_TYPE, include: boolean) => {
    if (!companyId.value || !activeIntegration.value?.id) return
    const accountingFields = cloneDeep(activeIntegration.value?.configuration?.accountingFields || []).map(
      (field: AccountingField) => {
        const types = include ? [...field.types, type] : field.types.filter((existingType) => existingType !== type)
        const updatedField = { target, types }
        // THIS IS A HACK (HOPEFULLY TEMPORARY) TO MAKE SURE FIELD DATA ARE IN CORRECT ORDER, TO AVOID VALIDATION ERROR
        const unchangedField = { target: field.target, types: field.types }
        return field.target === target ? updatedField : unchangedField
      }
    )
    return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.UPDATE_ACCOUNTING_CONFIG, {
      companyId: companyId.value,
      integration: activeIntegration.value,
      updatedConfig: {
        configuration: {
          accountingFields
        }
      }
    })
  }

  const enableAutoAccounting = (enable: boolean) => {
    if (!companyId.value || !activeIntegration.value?.id) return
    return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.ENABLE_AUTOACCOUNTING, {
      companyId: companyId.value,
      integrationId: activeIntegration.value.id,
      enable
    })
  }

  return {
    activeAccountingFields,
    availableAccountingFields,
    accountingEntriesEnabled,
    autoAccountingSyncEnabled,
    autoaccountingRules,
    companyAccountingObjects,
    referenceObjectTypes,
    accountingObjectsByType,
    createAccountingObject,
    deleteAccountingObject,
    downloadContextTemplate,
    enableAutoAccounting,
    loadAccountingObjects,
    loadAutoaccountingRules,
    updateAccountingObject,
    updateAccountingFields,
    uploadContextFile
  }
}
