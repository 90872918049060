import axios, {
  CancelTokenSource
} from 'axios'

import {
  STORE_MUTATIONS,
  STORE_ACTIONS,
  STORE_STATE,
  STORE_STATE_LOCAL_KEY
} from '@/enums'

import {
  UseCancelTokenResponse,
  StoreCancelToken
} from '@/models'

import useLocalStorage from '@/lib/useLocalStorage'

const actions = {
  [STORE_ACTIONS.USE_CANCEL_TOKEN] (
    { commit, state }: any,
    target: any
  ) {
    const payload: UseCancelTokenResponse = {
      canceled: false,
      token: null,
      target
    }

    const storeCancelToken = state[STORE_STATE.CANCEL_TOKENS]
      .find(({ target: _target }: StoreCancelToken) => _target === target)

    if (storeCancelToken) {
      storeCancelToken.token?.cancel('CANCELED')
      commit(STORE_MUTATIONS.REMOVE_CANCEL_TOKEN, target)
    } else {
      const _token: CancelTokenSource = axios.CancelToken.source()

      commit(STORE_MUTATIONS.SET_CANCEL_TOKEN, {
        target,
        token: _token
      })

      payload.canceled = false
      payload.token = _token
    }

    return payload
  },
  [STORE_ACTIONS.INIT_LOCALSTORAGE] (
    { state, commit }: any
  ) {
    if (!useLocalStorage.__Local) {
      const payloadString = JSON.stringify(state[STORE_STATE.LOCALSTORAGE])
      useLocalStorage.setItem('__Local', payloadString)
    }

    const existingLocal = JSON.parse(useLocalStorage.__Local || '{}')

    commit(STORE_MUTATIONS.SET_LOCALSTORE, existingLocal)
  },
  [STORE_ACTIONS.UPDATE_LOCALSTORAGE] (
    { commit }: any,
    { key, updateFn }: { key: STORE_STATE_LOCAL_KEY, updateFn: (state: any) => any }
  ) {
    if (!key) {
      return
    }

    const existingLocal = JSON.parse(useLocalStorage.__Local || '{}')
    existingLocal[key] = updateFn(existingLocal[key])

    localStorage.setItem('__Local', JSON.stringify(existingLocal))

    commit(STORE_MUTATIONS.SET_LOCALSTORE, existingLocal)
  }
}

export default actions
