import Axios from 'axios'
import { format, sub, addDays } from 'date-fns'
import queryCreator from '@/lib/queryCreator'
import { TRIPS_STORE_ACTIONS, TRIPS_STORE_MUTATIONS, CONFIG_STORE_GETTERS, SETTING_STORE_ACTIONS } from '@/enums'
import { DailyAllowanceItem, BankFormat } from '@/models'

export default {
  [TRIPS_STORE_ACTIONS.GET_FILTERED_TRIPS]: async ({ commit }: any, { params, config }: any) => {
    try {
      const {
        startDate = format(sub(new Date(), { months: 1 }), 'yyyy-MM-dd'),
        endDate = format(new Date(), 'yyyy-MM-dd'),
        companyId = null,
        summary = true,
        currencyTotals = true,
        transactionCount = true,
        userId = null,
        status = null,
        orderBy = 'createdAt',
        offset = 0,
        limit = null
      } = params

      if (!companyId) {
        return []
      }

      const endDatePlusOneDay = format(addDays(new Date(endDate), 1), 'yyyy-MM-dd')

      const tripsQuery_1 = queryCreator()
        .includeCurrencyTotals(currencyTotals)
        .includeTransactionCount(transactionCount)
        .paymentCard(true)
        .orderBy(orderBy)
        .where('createdAt', ({ isBetween }: any) => isBetween(startDate, endDatePlusOneDay))
        .base(`@api/core/v1/companies/${companyId}/alltrips`)
        .get()

      const tripsQuery_2 = queryCreator()
        .includeCurrencyTotals(currencyTotals)
        .includeTransactionCount(transactionCount)
        .paymentCard(true)
        .orderBy(orderBy)
        .where('createdAt', ({ isLesserThan }: any) => isLesserThan(startDate))
        .where('startDate', ({ isBetween }: any) => isBetween(startDate, endDate))
        .where('type', ({ isEqualWith }: any) => isEqualWith('expense'))
        .base(`@api/core/v1/companies/${companyId}/alltrips`)
        .get()

      const tripsQuery_3 = queryCreator()
        .includeCurrencyTotals(currencyTotals)
        .includeTransactionCount(transactionCount)
        .paymentCard(true)
        .orderBy(orderBy)
        .where('createdAt', ({ isGreaterThan }: any) => isGreaterThan(endDate))
        .where('startDate', ({ isBetween }: any) => isBetween(startDate, endDate))
        .where('type', ({ isEqualWith }: any) => isEqualWith('expense'))
        .base(`@api/core/v1/companies/${companyId}/alltrips`)
        .get()

      const { data: trips_1 = [] } = await Axios.get(tripsQuery_1)
      const { data: trips_2 = [] } = await Axios.get(tripsQuery_2)
      const { data: trips_3 = [] } = await Axios.get(tripsQuery_3)

      const allTrips = [...trips_1, ...trips_2, ...trips_3]

      commit(TRIPS_STORE_MUTATIONS.SET_TRIPS, allTrips)
    } catch (error) {
      console.error(TRIPS_STORE_ACTIONS.GET_FILTERED_TRIPS, error)
    }
  }, // TODO: these are too specific, should make it universal
  [TRIPS_STORE_ACTIONS.GET_UNSUBMITTED_CARD_REPORTS]: async ({ commit }: any, { params, config }: any) => {
    try {
      const { companyId = null } = params

      if (!companyId) {
        return []
      }

      const ccTripsQuery = queryCreator()
        .includeCurrencyTotals(true)
        .orderBy('createdAt')
        .where('subtype', ({ isEqualTo }: any) => isEqualTo('creditcard'))
        .status('Submitted', 'whereNotEqualTo')
        .base(`@api/core/v1/companies/${companyId}/alltrips`)
        .get()

      const dcTripsQuery = queryCreator()
        .includeCurrencyTotals(true)
        .orderBy('createdAt')
        .where('subtype', ({ isEqualTo }: any) => isEqualTo('debitcard'))
        .status('Submitted', 'whereNotEqualTo')
        .base(`@api/core/v1/companies/${companyId}/alltrips`)
        .get()

      const { data: ccTrips = [] } = await Axios.get(ccTripsQuery)
      const { data: dcTrips = [] } = await Axios.get(dcTripsQuery)

      const trips = [...ccTrips, ...dcTrips]

      commit(TRIPS_STORE_MUTATIONS.SET_TRIPS, trips)

      return trips
    } catch (error) {
      console.error(TRIPS_STORE_ACTIONS.GET_FILTERED_TRIPS, error)
    }
  },
  [TRIPS_STORE_ACTIONS.GET_TRIP]: async ({ commit }: any, payload: any = {}) => {
    try {
      const { companyId = '', tripId = '', options } = payload

      if (!companyId || !tripId) return

      let requestUrl = `@coreapi/companies/${companyId}/trips/${tripId}?currencyTotals=true&paymentCard=true`

      const { data = {} } = await Axios.get(requestUrl)

      commit(TRIPS_STORE_MUTATIONS.SET_TRIPS, [data])

      return data
    } catch (error) {
      console.error(TRIPS_STORE_ACTIONS.GET_TRIP, error)
      throw error
    }
  },
  [TRIPS_STORE_ACTIONS.NEW_TRIP]: async ({ commit, dispatch }: any, payload: any = {}) => {
    try {
      const { companyId = '', tripPayload = {} } = payload

      const { data = {} } = await Axios.post(`@coreapi/companies/${companyId}/trips`, tripPayload)

      commit(TRIPS_STORE_MUTATIONS.SET_TRIPS, [data])

      return data
    } catch (error) {
      console.error(TRIPS_STORE_ACTIONS.NEW_TRIP, error)
    }
  },
  [TRIPS_STORE_ACTIONS.SEND_TRIP_TO_SOFTWARE]: async ({ commit }: any, payload: any = {}) => {
    const { company, trip } = payload

    if (!company.id || !trip.id) return

    try {
      const { data } = await Axios.post(`@api/integrations/v1/send/${company.id}/report/${trip.id}`)
      commit(TRIPS_STORE_MUTATIONS.SET_TRIP_VALUES, { trip, values: { status: data?.status } })

      return data
    } catch (error) {
      throw error
    }
  },
  [TRIPS_STORE_ACTIONS.SUBMIT_TRIP]: async ({ commit }: any, payload: any = {}) => {
    const { company, trip } = payload

    if (!company.id || !trip.id) return

    try {
      const { data } = await Axios.post(`@coreapi/companies/${company.id}/trips/${trip.id}/submit`)
      commit(TRIPS_STORE_MUTATIONS.SET_TRIP_VALUES, { trip, values: { status: data?.status } })

      return data
    } catch (error) {
      throw error
    }
  },
  [TRIPS_STORE_ACTIONS.CONFIRM_AND_SEND]: async ({ commit, dispatch }: any, payload: any = {}) => {
    try {
      const { company, trip } = payload

      dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, true)

      const { data } = await Axios.post(`@coreapi/companies/${company.id}/trips/${trip.id}/ready`)
      commit(TRIPS_STORE_MUTATIONS.SET_TRIP_VALUES, { trip, values: { status: data?.status } })

      if (data.efAction === 'MARK_FOR_PROCESSING') {
        await dispatch(TRIPS_STORE_ACTIONS.SEND_TRIP_TO_SOFTWARE, { company, trip })
      }

      return data
    } catch (error) {
      throw error
    } finally {
      dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, false)
    }
  },
  [TRIPS_STORE_ACTIONS.UNSUBMIT_TRIP]: async ({ commit }: any, payload: any = {}) => {
    const { company, trip } = payload

    if (!company.id || !trip.id) return

    try {
      const { data } = await Axios.post(`@coreapi/companies/${company.id}/trips/${trip.id}/unsubmit`)
      commit(TRIPS_STORE_MUTATIONS.SET_TRIP_VALUES, { trip, values: { status: data?.status } })

      return data
    } catch (error) {
      throw error
    }
  },
  [TRIPS_STORE_ACTIONS.RESUBMIT_TRIP]: async ({ commit }: any, payload: any = {}) => {
    const { company, trip } = payload

    if (!company.id || !trip.id) return

    try {
      const { data } = await Axios.post(`@coreapi/companies/${company.id}/trips/${trip.id}/resubmit`)
      commit(TRIPS_STORE_MUTATIONS.SET_TRIP_VALUES, { trip, values: { status: data?.status } })

      return data
    } catch (error) {
      throw error
    }
  },
  [TRIPS_STORE_ACTIONS.DELETE_TRIP]: async ({ commit, dispatch }: any, payload: any = {}) => {
    try {
      const { company, trip } = payload

      dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, true)

      await Axios.delete(`@coreapi/companies/${company.id}/trips/${trip.id}`)

      commit(TRIPS_STORE_MUTATIONS.REMOVE_TRIP, trip.id)
    } catch (error) {
      throw error
    } finally {
      dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, false)
    }
  },
  [TRIPS_STORE_ACTIONS.REMOVE_TRIP]: async ({ commit, dispatch }: any, payload: any = {}) => {
    const { tripId } = payload
    commit(TRIPS_STORE_MUTATIONS.REMOVE_TRIP, tripId)
  },
  [TRIPS_STORE_ACTIONS.EXPORT_TRIP]: async ({ getters }: any, payload: any = {}) => {
    const { docType, companyId, tripId } = payload
    if (!docType || !companyId || !tripId) return

    try {
      const type = encodeURIComponent(docType)
      const { services } = getters[CONFIG_STORE_GETTERS.CONFIG]
      // const language = rootGetters['Config/CURRENT_LANGUAGE_ISO3']
      // TODO: get language and token from store
      const url = `${services.api.url}/export/v1/${companyId}/report/trip/${tripId}?access_token=${localStorage.token}&mediatype=${type}&lang=${localStorage.language}`
      window.open(url, '_blank')
    } catch (error) {
      throw error
    }
  },
  [TRIPS_STORE_ACTIONS.IMPORT_TRIP]: async ({ getters }: any, payload: any = {}) => {
    const { companyId, trip } = payload
    if (!companyId || !trip.id) return

    try {
      const { services } = getters[CONFIG_STORE_GETTERS.CONFIG]
      const url = `${services.api.url}/integrations/v1/export/${companyId}/export-report/${trip.id}?access_token=${localStorage.token}`
      return await Axios.get(url, { responseType: 'blob' })
    } catch (error) {
      throw error
    }
  },
  [TRIPS_STORE_ACTIONS.GET_ALLOWANCE]: async ({ commit }: any, payload: any = {}) => {
    const { companyId, tripId } = payload
    if (!companyId || !tripId) return

    try {
      Axios.get(`@coreapi/companies/${companyId}/trips/${tripId}/allowance`).then(({ data = [] }) => {
        commit(TRIPS_STORE_MUTATIONS.SET_DAILY_ALLOWANCE, { tripId, allowance: data })
      })
    } catch (error) {
      throw error
    }
  },
  [TRIPS_STORE_ACTIONS.SAVE_ALLOWANCE]: async (
    { commit }: any,
    payload: { companyId?: string | number; tripId?: string | number; newAllowanceEntry?: DailyAllowanceItem } = {}
  ) => {
    const { companyId, tripId, newAllowanceEntry } = payload
    if (!companyId || !tripId) return

    try {
      return Axios.post(`@coreapi/companies/${companyId}/trips/${tripId}/allowance`, newAllowanceEntry).then(
        ({ data = {} }) => {
          commit(TRIPS_STORE_MUTATIONS.SET_DAILY_ALLOWANCE_ITEM, { tripId, allowanceEntry: data })
        }
      )
    } catch (error) {
      throw error
    }
  },
  [TRIPS_STORE_ACTIONS.UPDATE_ALLOWANCE]: async ({ commit }: any, payload: any = {}) => {
    const { companyId, tripId, allowanceEntry } = payload
    const entryPayload = { ...allowanceEntry }
    delete entryPayload.id
    if (!companyId || !tripId || !allowanceEntry.id) return

    try {
      return Axios.put(
        `@coreapi/companies/${companyId}/trips/${tripId}/allowance/${allowanceEntry.id}`,
        entryPayload
      ).then(({ data = {} }) => {
        commit(TRIPS_STORE_MUTATIONS.SET_DAILY_ALLOWANCE_ITEM, { tripId, allowanceEntry: data })
      })
    } catch (error) {
      throw error
    }
  },
  [TRIPS_STORE_ACTIONS.DELETE_ALLOWANCE]: async ({ commit }: any, payload: any = {}) => {
    const { companyId, tripId, allowanceEntryId } = payload
    if (!companyId || !tripId || !allowanceEntryId) return

    try {
      return Axios.delete(`@coreapi/companies/${companyId}/trips/${tripId}/allowance/${allowanceEntryId}`).then(() => {
        commit(TRIPS_STORE_MUTATIONS.REMOVE_DAILY_ALLOWANCE_ITEM, { tripId, allowanceEntryId })
      })
    } catch (error) {
      throw error
    }
  },
  [TRIPS_STORE_ACTIONS.GET_STATEMENT]: async ({ commit }: any, payload: any = {}) => {
    const { companyId, tripId } = payload
    if (!companyId || !tripId) return

    try {
      return Axios.get(`@coreapi/companies/${companyId}/card-reports/${tripId}/statement`).then(
        ({ data: statement = {} }) => {
          commit(TRIPS_STORE_MUTATIONS.SET_STATEMENT, { tripId, statement })
          return statement
        }
      )
    } catch (error) {
      throw error
    }
  },
  [TRIPS_STORE_ACTIONS.DELETE_STATEMENT]: async ({ commit }: any, payload: any = {}) => {
    const { companyId, tripId } = payload
    if (!companyId || !tripId) return

    try {
      return Axios.delete(`@coreapi/companies/${companyId}/card-reports/${tripId}/statement`).then(() => {
        commit(TRIPS_STORE_MUTATIONS.SET_STATEMENT, { tripId, statement: {} })
      })
    } catch (error) {
      throw error
    }
  },
  [TRIPS_STORE_ACTIONS.GET_STATEMENT_FORMATS]: async ({ commit }: any, payload: any = {}) => {
    const { companyId } = payload

    if (!companyId) return

    try {
      return Axios.get(`@coreapi/companies/${companyId}/card-reports/supported-statement-formats`).then(
        ({ data: supportedFormats = [] }) => {
          const banksMerged: Array<BankFormat> = []
          supportedFormats.forEach((bank: BankFormat) => {
            const existingIndex = banksMerged.findIndex(({ id, country }) => id === bank.id && country === bank.country)
            if (existingIndex > -1) {
              banksMerged[existingIndex].format = `${banksMerged[existingIndex].format}, ${bank.format}`
            } else {
              banksMerged.push(bank)
            }
          })
          commit(TRIPS_STORE_MUTATIONS.SET_STATEMENT_FORMATS, banksMerged)
          return banksMerged
        }
      )
    } catch (error) {
      throw error
    }
  },
  [TRIPS_STORE_ACTIONS.UPDATE_TRIP_VALUES]: async ({ commit, dispatch }: any, payload: any = {}) => {
    try {
      const { company = {}, trip = {}, values = {} } = payload

      dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, true)

      const { data } = await Axios.put(`@api/core/v1/companies/${company.id}/trips/${trip.id}`, values)

      commit(TRIPS_STORE_MUTATIONS.SET_TRIP_VALUES, { trip, values: { ...data } })
    } catch (error) {
      throw error
    } finally {
      dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, false)
    }
  }
}
