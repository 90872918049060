import { COMPANIES_STORE_MUTATIONS, COMPANIES_STORE_STATE } from '@/enums'
import { CompaniesStoreState, Company, CompanySettings } from '@/models'

export default {
  [COMPANIES_STORE_MUTATIONS.SET_COMPANIES]: (state: CompaniesStoreState, companies: Company[]) => {
    for (const company of companies) {
      if (state[COMPANIES_STORE_STATE.COMPANIES][company.id]) {
        state[COMPANIES_STORE_STATE.COMPANIES][company.id] = {
          ...state[COMPANIES_STORE_STATE.COMPANIES][company.id],
          ...company
        }
      } else {
        state[COMPANIES_STORE_STATE.COMPANIES][company.id] = company
      }
    }
  },
  [COMPANIES_STORE_MUTATIONS.SET_COMPANY_VALUES]: (state: CompaniesStoreState, payload: any = {}) => {
    const { companyId, values } = payload

    state[COMPANIES_STORE_STATE.COMPANIES][companyId] = {
      ...state[COMPANIES_STORE_STATE.COMPANIES][companyId],
      ...values
    }
  },
  [COMPANIES_STORE_MUTATIONS.SET_COMPANY_SETTINGS]: (state: CompaniesStoreState, payload: any = {}) => {
    const { companyId, updatedSettings }: { companyId: string | number; updatedSettings: CompanySettings } = payload

    for (const [key, value] of Object.entries(updatedSettings)) {
      //@ts-ignore
      state[COMPANIES_STORE_STATE.COMPANIES][companyId]['settings'][key] = value
    }
  }
}
